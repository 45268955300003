<template>
  <div>
    <!-- Table Container Card -->
    <city-add-new
      :is-add-city-sidebar-active.sync="isAddCitySidebarActive"
      @refetch-data="refetchData"
      v-if="isAddCitySidebarActive"
    />

    <edit-city
      :is-edit-city-sidebar-active.sync="isEditCitySidebarActive"
      :city-area="cityRef"
      @refetch-data="refetchData"
      v-if="isEditCitySidebarActive"
    />
    <div style="display: flex; justify-content: flex-end; margin: 20px 0">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        @click="isAddCitySidebarActive = true"
        variant="primary"
      >
        {{ $t("Add city area") }}
      </b-button>
    </div>
    <b-card no-body class="mb-0">
      <!-- <b-form class="p-1">
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Search')" label-for="name">
              <b-form-input id="name" v-model="nameRef" debounce="500" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form> -->
      <b-table
        ref="refCityListTable"
        class="position-relative"
        :items="fetchCity"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="setCity(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t("Edit") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{
              $t("Pagination text", [dataMeta.from, dataMeta.to, dataMeta.of])
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCity"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BForm,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import userCityList from "./useCityAreaList";
import { ref } from "@vue/composition-api";
import CityAddNew from "./CityAreaAddNew.vue";
import EditCity from "./EditCityArea.vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    CityAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    EditCity,
    BFormGroup,
    BForm,

    vSelect,
  },

  setup(props) {
    const isAddCitySidebarActive = ref(false);
    const isEditCitySidebarActive = ref(false);
    const cityRef = ref({});

    const {
      fetchCity,
      tableColumns,
      perPage,
      currentPage,
      totalCity,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCityListTable,
      refetchData,
      nameRef,
    } = userCityList();

    function setCity(city) {
      isEditCitySidebarActive.value = true;
      cityRef.value = city;
    }

    return {
      // Sidebar
      isAddCitySidebarActive,
      isEditCitySidebarActive,
      fetchCity,
      tableColumns,
      perPage,
      currentPage,
      totalCity,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refCityListTable,
      refetchData,
      setCity,
      cityRef,
      // Filter
      avatarText,
      nameRef,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
